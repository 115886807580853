import { createSlice } from "@reduxjs/toolkit";
import {mutations} from "./mutations";
const providerSlice = createSlice({
    name: 'adminProviders',
    initialState: {
        displayItems: [],
        pagination: { total: 0, per_page: 10, from: 1, to: 0, current_page: 1, last_page: 1, offset: 3, pagination_numbers: [] },
        selectedItems: [],
        modal: { deleteOne: false, deleteMany: false, create: false, deleteAll:false, import: false },
        filter: {
            value: {
                name: { value: '', translated: 'nome', type: 'list-text-value' }
            },
            original: {},
            fullPath: ''
        },
        formOptions: {
            value: {category: [], classification: [], status: [], gender: []},
            original: {},
            items: []
        },
        componentIsLoaded: false
    },
    reducers: {
        clear_filters (state) {
            state.filter.value.name.value = ''
        },
        ...mutations
    }
})

export const pagination = (state) => state.adminProviders.pagination;
export const displayItems = (state) => state.adminProviders.displayItems;
export const formOptions = (state) => state.adminProviders.formOptions;
export const selectedItems = (state) => state.adminProviders.selectedItems;
export const modal = (state) => state.adminProviders.modal;
export const filter = (state) => state.adminProviders.filter;
export const initFilterValue = () => {
    return {name: { value: '', translated: 'nome', type: 'list-text-value' }}
};
export const componentIsLoaded = (state) => state.adminProviders.componentIsLoaded;
export const providerActions = providerSlice.actions;
export default providerSlice.reducer
export const optionSelected = (data) => {
    const idSelected = data.idSelected
    const options = data.options
    const titleSelected = data.titleSelected ? data.titleSelected : 'title'
    const idName = data.idName ? data.idName : 'id'

    for (const prop in options) {
        if (options[prop][idName] === idSelected) {
            return capitalizeText(options[prop][titleSelected])
        }
    }
}

export const capitalizeText = (text) => {
    if (!text) {
        return
    }
    const splitText = text.split(' ')

    const newString = []
    for (let i = 0; i < splitText.length; i++) {
        newString.push(capitalizeFirstLetter(splitText[i]))
    }

    return newString.join(' ')
}

export const capitalizeFirstLetter = (string) => {
        if (!string) {
            return ''
        }
        return string.charAt(0).toUpperCase() + string.slice(1)
}
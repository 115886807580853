const Home = () => {
    return (<div className="content">
        <div className="card">
            <div className="card-body  card_form ">
                <p>Você está na área de forncedores</p>
            </div>
        </div>
    </div>);
};

export default Home;
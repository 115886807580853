import { useDispatch, useSelector } from "react-redux"
import { pagination,
    displayItems,
    modal,
    filter,
    formOptions,
    initFilterValue } from "../../../store/modules/admin/providersSlice"
import { setLoading } from "../../../store/modules/admin/mainSliceAction"
import {
    changePagePagination,
    loadDisplay,
    setFiltersList,
    setInitialValues,
    setModal,
    setSelectedItems,
    setFilter,
    clearFilters
} from "../../../store/modules/admin/providersSliceActions"
import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalDelete from "./inc/ModalDelete";
import Pagination from "../../../components/Pagination"
import OneSeach from "../../../components/OneSeach"
import ModalImport from "./inc/ModalImport"
import SetUrlFilerString from "../../../hooks/SetUrlFilerString"
import MakeFilterSearchUrl from "../../../hooks/MakeFilterSearchUrl"
import FacyUrl from "../../../hooks/FacyUrl"
import {Link, useLocation} from "react-router-dom"
const Display = () => {

    const [componentIsLoaded, setComponentIsLoaded] = useState(false);
    const dispatch = useDispatch()
    const displayItemsData = useSelector(displayItems)
    const paginationsData = useSelector(pagination)
    const filtersData = useSelector(filter)
    const formOptionsData = useSelector(formOptions)
    const initFilterValueData = useSelector(initFilterValue)

    const modalData = useSelector(modal)
    const location = useLocation()
    const paramters = new URLSearchParams(location.search)


    const openDeleteModal = event => {
        const index = event.currentTarget.id
        dispatch(setSelectedItems(index))
        dispatch(setModal({ 'name': 'deleteOne', 'value': true }) )
    };

    const closeDeleteModal = event => {
        dispatch(setModal({ 'name': 'deleteOne', 'value': false }))
    };

    const closeDeleteAllModal = () => {
        dispatch(setModal({ 'name': 'deleteAll', 'value': false }))
        changePage(1)
    };

    const openDeleteAllModal = () => {
        dispatch(setModal({ 'name': 'deleteAll', 'value': true }) )
    };

    const closeImportModal = event => {
        changePage(1)
        dispatch(setModal({ 'name': 'import', 'value': false }))
    };

    const openImportModal = event => {
        dispatch(setModal({ 'name': 'import', 'value': true }) )
    }

    const changePage = page => {
        // SET URL STRING TO SEARCH
        const pgFilterURLString = new SetUrlFilerString(filtersData.value, filtersData.original).getValue(page)
        const newURL = new FacyUrl(filtersData, formOptionsData).getURL(page, null)
        // LOADING
        dispatch(setLoading(true))

        Promise.all([
            dispatch(changePagePagination(page)),
            dispatch(loadDisplay(pgFilterURLString))
        ]).then((res) => {
            dispatch(setLoading(false))
            window.history.pushState('', '', newURL)
        })

    };

    const loadFilters = filter => {
        dispatch(setFilter(filter))
    };
    // CHANGE FILTER
    useEffect(() => {
        if(componentIsLoaded === true) {
            // const page = paginationsData.current_page
            const page = 1
            const pgFilterURLString = new SetUrlFilerString(filtersData.value, filtersData.original).getValue(page)
            const newURL = new FacyUrl(filtersData, formOptionsData).getURL(page, null)
            Promise.all([
                dispatch(changePagePagination(page)),
                dispatch(loadDisplay(pgFilterURLString)),
            ]).then((res) => {
                dispatch(setLoading(false))
                window.history.pushState('', '', newURL)
            })
        }
    }, [filtersData.value.name.value])


    useEffect(() => {
        const firtFilterValue = initFilterValueData
        // GET PAGA
        const page = paramters.get('pagina') ? paramters.get('pagina') : 1
        // SET FILTER VALUE
        const filterSearchURL = new MakeFilterSearchUrl(firtFilterValue, formOptionsData.value, location.search).getAllFilters()
        // SET URL STRING TO SEARCH
        const pgFilterURLString = new SetUrlFilerString(filterSearchURL, firtFilterValue).getValue(page)
        // SET ORIGINAL VALUES
        Promise.all([
            setComponentIsLoaded(false),
            dispatch(clearFilters()),
            dispatch(setLoading(true)),
            dispatch(setInitialValues({path:location.search, filters: firtFilterValue, page: page})),
            dispatch(setFiltersList(filterSearchURL)),
            dispatch(loadDisplay(pgFilterURLString))
        ]).then((res) => {
            dispatch(setLoading(false))
            setComponentIsLoaded(true)
        })
    }, []);

    return (
        <div className="content">
            {(function() {
                if (modalData.deleteOne) {
                    return <ModalDelete onClose={closeDeleteModal} type="delete-one" />
                }
                if (modalData.deleteAll) {
                    return <ModalDelete onClose={closeDeleteAllModal} type="delete-all" />
                }
                if (modalData.import) {
                    return <ModalImport onClose={closeImportModal}  />
                }
            })()}

            {/*
            <modal-delete v-if="modal('deleteMany')" type="deleteMany" ></modal-delete>*/}
            <div className="columns">
                <div className="title-area-display">
                    <h1 className="main-title">Forncedores</h1>
                </div>
                <div className="column">
                    <ul className="nav-btn-actions-top">
                        <li><OneSeach namefilter="name"
                                      initialvalue={filtersData.value.name.value}
                                      onLoadfilters={loadFilters}  /></li>
                        {/*<li><a  className="button btn-light"  ><FontAwesomeIcon icon="trash-alt" /> Deletar</a></li>*/}
                        <li><a className="button btn-light"  onClick={openImportModal} ><FontAwesomeIcon icon="file-alt" /> Importar</a></li>
                        <li><a className="button btn-light"  onClick={openDeleteAllModal} ><FontAwesomeIcon icon="file-alt" /> Deletar Todos</a></li>
                        <li><Link  className="button btn-light"  to="/admin/fornecedores/criar" ><FontAwesomeIcon icon="plus" /> Inserir</Link></li>
                    </ul>
                </div>
            </div>
            <div className="columns" id="main-content" >
                {componentIsLoaded &&
                    <div className="column">
                        {displayItemsData.length ?
                            <div className="card">
                                <div className="card-header card-display">
                                    <p> Registros Encontrados - {paginationsData.total} / Página atual
                                        - {paginationsData.current_page}</p>
                                </div>
                                <div className="card-body">
                                    <table className="table table-display">
                                        <thead>
                                        <tr>
                                            {/*<th>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={true} name="select-all" label="select-all"/>
                                                            }
                                                            label="Selecionar Todos" />
                                                    </th>*/}
                                            <th>
                                                Imagem
                                            </th>
                                            <th>
                                                Informações
                                            </th>

                                            <th className="col_action">
                                                Ação
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {displayItemsData.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    {/*<td>
                                                            <Checkbox checked={false} name={'select-' + item.id} />
                                                        </td>*/}
                                                    <td>
                                                        <p className="description">
                                                            {item.document_type === 'commercial' &&
                                                                <span>CNPJ: {item.cnpj} <br />
                                                                      Razão Social: {item.razao_social}<br /></span>
                                                            }

                                                            {item.document_type === 'personal' &&
                                                                <span>CPF: {item.cpf} <br />
                                                                      Nome: {item.name} {item.last_name}<br />

                                                                </span>
                                                            }

                                                            Cód. Totz: {item.cod_totvs} <br/>

                                                        </p>


                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td className="col_action">
                                                        <ul className="actions-table-list">
                                                            <li>
                                                                <Link className="link edit_btn hint--top"
                                                                      to={`/admin/fornecedores/editar/${item.id}`}
                                                                      aria-label="Editar">
                                                                    <FontAwesomeIcon icon="edit"/>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <a className="link delete_btn hint--top" href="#"
                                                                   aria-label="Deletar" id={index}
                                                                   onClick={openDeleteModal}>
                                                                    <FontAwesomeIcon icon="trash-alt"/>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div> : <div className="card card-empty-result">
                                <div className="card-body"><h4>Não Há Resutados</h4></div>
                            </div>}
                        <Pagination title="Fornecedor Encontrado"
                                    titleplural="Fornecedores Encontrados"
                                    paginationdata={paginationsData}
                                    changepage={changePage}/>
                    </div>
                }
            </div>
        </div>);
};

export default Display;
import { createSlice } from "@reduxjs/toolkit";
import {mutations} from "./mutations";

const mainSlice = createSlice({
    name: 'adminMain',
    initialState: {
        loading: false
    },
    reducers: {
        ...mutations
    }
})
export const loading = (state) => state.adminMain.loading;
export const adminMainActions = mainSlice.actions;
export default mainSlice.reducer
import {Outlet} from "react-router-dom";
import './Modal.css'
import React from 'react';
import PropTypes from "prop-types";

const Home = (props) => {

    const modalStyleArea = props.modalstyle === null ? 'modal-container' : props.modalstyle + ' modal-container'

    return (<div className="modal-mask"   >
                <div className="modal-wrapper"  >
                    <div className={modalStyleArea} >
                    <a className="close-modal" onClick={props.onClose} ></a>
                    <div className="modal-header" >
                    </div>
                     {props.children ? props.children : <Outlet />}
              </div>
            </div>
        </div>);
};

Home.propTypes = {
    modalstyle: PropTypes.string,
    btnclose: PropTypes.bool,
    closeoutside: PropTypes.bool
}

Home.defaultProps = {
    modalstyle: null,
    btnclose: true,
    closeoutside: false
}

export default Home;


class SetUrlFilerString {
    constructor (data, originalData) {
        this.pgFilpers = this.makePGFilter(data, originalData)
    }

    getValue (paginationPage) {
        const paginateProp = paginationPage ? 'page=' + paginationPage : ''

        return {
            filters: this.pgFilpers.filters,
            hasFilterSelected: this.pgFilpers.hasFilterSelected,
            pagination: paginateProp
        }
    }

    makePGFilter (data, originalData) {
        const valueFilertPage = this.pushFilters(Object.keys(data), data, originalData)
        return {
            filters: this.joinValuePageFilter(valueFilertPage.filters),
            hasFilterSelected: valueFilertPage.hasFilterSelected
        }
    }

    // ADD & BETWEEN PAGE VALUES
    joinValuePageFilter (value) {
        let string = ''
        for (const prop in value) {
            // add if has element
            if (value[prop]) {
                string = string !== '' ? value[prop] + '&' + string : value[prop]
            }
        }

        return string
    }

    pushFilters (allFilters, searchOption, originalData) {
        const filters = []
        let hasFilterSelected = false
        allFilters.forEach((filterKey, index) => {
            const filterValue = searchOption[filterKey]
            if (!this.isEmpty(filterValue.value) || !this.isBlank(filterValue.value)) {
                // PUSH INTO FILTERS
                filters.push(this.joinValuesArrayFilters(filterValue.value, filterKey))

                // VERIFY IF HAS FILTER SELECTED
                if (this.verifyHasFilter(originalData[filterKey])) {
                    hasFilterSelected = true
                }
            }
        })

        return { filters: filters, hasFilterSelected: hasFilterSelected }
    }

    verifyHasFilter (dataFilter) {
        if (Object.prototype.hasOwnProperty.call(dataFilter, 'hidden_search')) {
            if (dataFilter.hidden_search === true) {
                return false
            }
        }

        return true
    }

    isBlank (str) {
        return (!str || /^\s*$/.test(str))
    }

    isEmpty (str) {
        return (!str || str.length === 0)
    }

    // add category=  and - between values
    joinValuesArrayFilters (value, nameFilter) {
        // IS ARRAY
        if (Array.isArray(value)) {
            // HAS SEGMENT
            let string = ''

            const valueReverse = this.reverseArray(value)
            for (const prop in valueReverse) {
                const newValue = valueReverse[prop] ? valueReverse[prop] : ''
                const separator = newValue !== '' ? '-' : ''
                string = string !== '' ? newValue + separator + string : newValue
            }
            return string !== '' && string !== '0-0' && string !== '0' ? nameFilter + '=' + string : ''
        }

        // IS STRING // NUMBER
        return value ? nameFilter + '=' + value : ''
    }

    reverseArray (value) {
        const newValue = []
        for (const prop in value) {
            newValue.unshift(value[prop])
        }

        return newValue
    }
};
export default SetUrlFilerString

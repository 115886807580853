import Modal from "../../../../components/Modal";
import React, {useEffect, useState} from 'react'
import SubmitBtn from "../../../../components/SubmitBtn";
import {destroy, destroy_all} from "../../../../store/modules/admin/providersSliceActions";
import {useDispatch, useSelector} from "react-redux";
import {displayItems, selectedItems} from "../../../../store/modules/admin/providersSlice";
import Swal from "sweetalert2";
const ModalDelete = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        procesing: false,
        items: [{}]
    })
    const selectedItemsData = useSelector(selectedItems)
    const displayItemsData = useSelector(displayItems)

        //[{ id: this.displayItems[this.selectedIndex].id, index: this.selectedIndex }]
    useEffect(() => {
        const list = []
        selectedItemsData.forEach((item, i) => {
            list.push({ id: displayItemsData[item].id, index: item })
        })
        // SET DATA
        setData ((prevState) =>
            { return { ...prevState, items: list  }
        })
    }, []);

    const submitHandler = event => {
        event.preventDefault();
        setData({procesing: true})
        // filters
        const wait = setTimeout(() => {
                clearTimeout(wait)

            if(props.type == 'delete-one') {
                dispatch(destroy(data.items)).then((res) => {
                }).then(() => {
                    setData({procesing: false})
                    dispatch(props.onClose)
                }).catch(error => {
                    // SET ERROR 500
                    if(error.status === 500) {
                        Swal.fire({
                            title: 'Erro !!',
                            text: error.data.message,
                            icon: 'error'
                        })
                    }
                })
            }

            if(props.type == 'delete-all') {
                dispatch(destroy_all()).then((res) => {
                }).then(() => {
                    setData({procesing: false})
                    dispatch(props.onClose)
                }).catch(error => {
                    // SET ERROR 500
                    if(error.status === 500) {
                        Swal.fire({
                            title: 'Erro !!',
                            text: error.data.message,
                            icon: 'error'
                        })
                    }
                })
            }


        }, 2000)
    }

    return (<Modal onClose={props.onClose} >
        <form method="POST" onSubmit={submitHandler} >
                <div className="modal-body">
                    { props.type == 'delete-one' ?  <p>Tem Certeza que deseja deletar  este forncedor ?</p> : '' }
                    { props.type == 'delete-all' ?  <p>Tem Certeza que deseja deletar  todos  os fornecedores ?</p> : '' }
                </div>
                <div className="modal-footer buttons is-centered">
                    <button type="button" className="button is-light btn_cl_left"  onClick={props.onClose} ><span>Fechar</span></button>
                    <SubmitBtn
                        processloading={data.procesing}
                        stylebutton="button btn_cl_left  add-alert is-info"
                        textbutton="Deletar"
                    />
                </div>
            </form>
        </Modal>);
};

export default ModalDelete;
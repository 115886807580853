import {useNavigate, useSearchParams} from "react-router-dom"
import { useContext, useEffect, useState } from 'react'
import AuthContext from '../store/modules/authContext'
import {getAuth, getCurrentUserInfo} from "../store/modules/adminLogin/adminLoginSlice"
import LoadingBar from "../components/LoadingBar";
import { useDispatch, useSelector } from "react-redux"
const CallBack = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const authCtx = useContext(AuthContext);
    useEffect(() => {
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        dispatch(getAuth({code: code, state: state })).then(token => {
            // REGISTER TOKEN
            // authCtx.login(token);
            // GET USER INFO
            dispatch(getCurrentUserInfo(token)).then(userInfo => {
                console.log('user infooooo -->', userInfo)
                authCtx.login({...userInfo , ...{token: token}})
            }).then( () => {
                const wait = setTimeout(() => {
                    clearTimeout(wait)
                    navigate('/admin/home')
                }, 2000)
            })
        }).catch(error => {
            navigate('/?status=' + error.data.status)
        }).then( () => {
            setLoading(false)
            // window.location.href = '/admin/home'
        });

    }, []);
    return (<section>
        {loading && <LoadingBar /> }
    </section>);
};
export default CallBack;

import Modal from "../../../../components/Modal";
import React, {useEffect, useState} from 'react'
import SubmitBtn from "../../../../components/SubmitBtn";
import {useDispatch, useSelector} from "react-redux";
import {save, loadFormOptions} from "../../../../store/modules/admin/accessSliceAction";
import {formOptions} from "../../../../store/modules/admin/accessSlice";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
const ModalCreate = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const formOptionsData = useSelector(formOptions)
    const [data, setData] = useState({
        procesing: false,
        user_access_id: []
    })

    useEffect(() => {
        dispatch(loadFormOptions('form'))

    }, []);

    const UserAccessChangeHandler = (value) => {
        setData ((prevState) =>
        { return { ...prevState, user_access_id: value  }
        })
    }

    const submitHandler = event => {
        event.preventDefault();
        setData ((prevState) =>
        { return { ...prevState, procesing: true  }
        })

        // filters
        const wait = setTimeout(() => {
            clearTimeout(wait)
            const formData = {
                user_access_id: data.user_access_id
            }
            // DISPATCH
            dispatch(save({ data: formData})).then((res) => {
                // SUCCESS MESSAGE
                Swal.fire({
                    title: 'Suceso !!',
                    text:  `Permissões Criadas com Sucesso`,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    props.onCloseAndReload()
                    console.log('foiiiiii')
                })
            }).catch(error => {
                // SET ERROR 500
                if(error.status === 500) {
                    Swal.fire({
                        title: 'Erro !!',
                        text: error.data.message,
                        icon: 'error'
                    })
                    return
                }
            }).then(() => {
                // LOADING FALSE
                setData ((prevState) =>
                { return { ...prevState, procesing: false  }
                })
            })
        })
    }

    return (<Modal modalstyle="big1" onClose={props.onClose} >
        <form method="POST" onSubmit={submitHandler} >
            <div className="modal-body">
              <h1>Inserir Acesso a Usuário</h1>
                <div className="field">
                    <label className="is-left">Usuários</label>
                    <div className="control">
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={formOptionsData.value.user_access}
                            getOptionLabel={(option) => option.title}
                            value={formOptionsData.value.user_access.filter((item) => {
                                return data.user_access_id.includes(item.id)
                            })}
                            onChange={(event, newValue) => {
                                const data = newValue.filter((item, index) => {
                                    return item
                                }).map(key => key.id)
                                UserAccessChangeHandler(data)
                            }}

                            renderInput={(params) => (
                                <TextField {...params}  />
                            )}
                        />
                    </div>
            </div>
            <div className="modal-footer buttons is-centered">
                <button type="button" className="button is-light btn_cl_left"  onClick={props.onClose} ><span>Fechar</span></button>
                <SubmitBtn
                    processloading={data.procesing}
                    stylebutton="button btn_cl_left  add-alert is-info"
                    textbutton="Autorizar Acesso"
                />
            </div>
            </div>
        </form>
    </Modal>);
};

export default ModalCreate;
import './OneSearch.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react'
const OneSeach = (props) => {
    const [filterTitle, setFilterTitle] = useState('')
    useEffect(() => {
        setFilterTitle(props.initialvalue)
    }, [props.initialvalue]);

    const filterTitleChangeHandler = (event) => {
        setFilterTitle (event.target.value)
    }

    const sendFilter = event => {
        props.onLoadfilters({ name: props.namefilter, value: filterTitle })
    };

    const clearInput = event => {
        setFilterTitle ('')
        props.onLoadfilters({ name: props.namefilter, value: '' })
    };

    return (<div className="relative div_search">
                { filterTitle  &&  <a className="clean_btn" onClick={clearInput} ><FontAwesomeIcon icon="times-circle" /></a> }
                <input type="text" className="form-control search-input"  value={filterTitle} onChange={filterTitleChangeHandler} placeholder={props.placeholder} />
                <button className="button is-info search-top-btn" onClick={sendFilter} ><FontAwesomeIcon icon="search" className="right" /></button>
            </div>);
};

OneSeach.propTypes = {
            namefilter: PropTypes.string,
            initialvalue: PropTypes.string,
            placeholder: PropTypes.string
    }

OneSeach.defaultProps = {
             namefilter: null,
            initialvalue: null,
            placeholder: 'Busque pelo Título'
    }
export default OneSeach;



import { createSlice } from "@reduxjs/toolkit";
import {mutations} from "./mutations";
const usersSlice = createSlice({
    name: 'adminUsers',
    initialState: {
        displayItems: [],
        pagination: { total: 0, per_page: 10, from: 1, to: 0, current_page: 1, last_page: 1, offset: 3, pagination_numbers: [] },
        selectedItems: [],
        modal: { deleteOne: false, deleteMany: false, deleteAll:false, import: false },
        filter: {
            value: {
                email: { value: '', translated: 'email', type: 'list-text-value' },
                status: { value: '', translated: 'status' },
                area: { value: '', translated: 'area' },
                manager: { value: '', translated: 'gerente' },
                is_manager: { value: '', translated: 'egerente' },
                name: { value: '', translated: 'nome', type: 'list-text-value' },
                last_name: { value: '', translated: 'sobrenome', type: 'list-text-value' },
                cpf: { value: '', translated: 'cpf', type: 'list-text-value' },
            },
            original: {},
            fullPath: ''
        },
        formOptions: {
            value: {status: [], areas: []},
            original: {},
            items: []
        },
        componentIsLoaded: false
    },
    reducers: {
        clear_filters (state) {
            state.filter.value.name.value = ''
            state.filter.value.status.value = ''
            state.filter.value.area.value = ''
            state.filter.value.manager.value = ''
            state.filter.value.is_manager.value = ''
            state.filter.value.name.value = ''
            state.filter.value.last_name.value = ''
            state.filter.value.cpf.value = ''
        },
        ...mutations
    }
})

export const pagination = (state) => state.adminUsers.pagination;
export const displayItems = (state) => state.adminUsers.displayItems;
export const formOptions = (state) => state.adminUsers.formOptions;
export const selectedItems = (state) => state.adminUsers.selectedItems;
export const modal = (state) => state.adminUsers.modal;
export const filter = (state) => state.adminUsers.filter;
export const initFilterValue = () => {
    return {email: { value: '', translated: 'email', type: 'list-text-value' },
            status: { value: '', translated: 'status' },
            area: { value: '', translated: 'area' },
            manager: { value: '', translated: 'gerente' },
            is_manager: { value: '', translated: 'egerente' },
            name: { value: '', translated: 'nome', type: 'list-text-value' },
            last_name: { value: '', translated: 'sobrenome', type: 'list-text-value' },
            cpf: { value: '', translated: 'cpf', type: 'list-text-value' }}
};
export const componentIsLoaded = (state) => state.adminUsers.componentIsLoaded;
export const userActions = usersSlice.actions;
export default usersSlice.reducer
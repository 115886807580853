import './SubmitBtn.css'
import PropTypes from "prop-types";
const Pagination = (props) => {

    const goPrevPage = event => {
        props.changepage(props.paginationdata.current_page - 1)
    };

    const goNextPage = event => {
        props.changepage(this.props.paginationdata.current_page + 1)
    };

    const changePage = (page) => {
        props.changepage(page)
    };


    return (<div className="columns" >
        {props.paginationdata.total ?
                <div className="column">
                    <div className="pagination-total" >
                            <p className="paginationRegister"><span className="sp2"> {props.paginationdata.total} </span> {props.titleplural}</p>
                    </div>
                    <nav className="pagination is-rounded is-centered" role="navigation"  >
                        {props.paginationdata.current_page > 1 &&
                            <a href="#" aria-label="Previous" className="pagination-previous" onClick={goPrevPage} >
                                <span aria-hidden="true">«</span>
                            </a>
                        }
                        {props.paginationdata.current_page < props.paginationdata.last_page &&
                              <a  href="#" aria-label="Next" className="pagination-next"  onClick={goNextPage} >
                                <span aria-hidden="true">»</span>
                            </a>
                        }

                        {props.paginationdata.total > props.paginationdata.per_page &&
                            <ul className="pagination-list">
                                {props.paginationdata.pagination_numbers.map((page, index) => {
                                    return (<li key={index} >
                                                <a href="#" onClick={() => changePage(page)}
                                                   className={page === props.paginationdata.current_page ? 'pagination-link is-current' : 'pagination-link'}>
                                                    {page}
                                                </a>
                                    </li> );
                                })}
                            </ul>
                        }
                    </nav>
             </div> : '' }
    </div>);
};

Pagination.propTypes = {
    title: PropTypes.string,
    titleplural: PropTypes.string,
    paginationdata: PropTypes.object,
    showpaginationtotal: PropTypes.bool
}

Pagination.defaultProps = {
    title: null,
    titleplural: null,
    paginationdata: {},
    showpaginationtotal: true
}

export default Pagination;
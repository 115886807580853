class FacyUrl {
  constructor (selectSearch, formOptions) {
    this.selectSearchOriginal = selectSearch.original
    this.newSelectSearchData = selectSearch.value
    this.formOptions = formOptions.value
    this.segment = []
  }

  getURL (pagination = null) {
    // options type , city , neighborhoods ....
    // SHOW ALL OPTINS AND MAKE SEARCH
    const list = this.searchAndSetAllOptions()

    // IS EMPTY
    if (list.listOptions === '' && list.segmentOptions === '') {
      return '?' + this.makePagination(pagination)
    }

    // HAS SEGMENT
    if (list.segmentOptions) {
      return '/' + list.segmentOptions + '?' + list.listOptions + this.makePagination(pagination, true)
    }

    // DONT HAS SEGMENT
    return '?' + list.listOptions + this.makePagination(pagination, true)
  }

  getURLNoPageinate () {
    // SHOW ALL OPTINS AND MAKE SEARCH
    const list = this.searchAndSetAllOptions()

    // IS EMPTY
    if (list.listOptions === '' && list.segmentOptions === '') {
      return
    }

    // HAS SEGMENT
    if (list.segmentOptions) {
      return '/' + list.segmentOptions + '?' + list.listOptions
    }
    // DONT HAS SEGMENT
    return '?' + list.listOptions
  }

  makePagination (pagination, hasFilter = false) {
    const separator = hasFilter ? '&' : ''
    const prefix = 'pagina='

    if (pagination === 0 || pagination === '' || pagination === null) {
      return ''
    }
    return hasFilter ? String(separator + prefix + pagination) : String(prefix + pagination)
  }

  searchAndSetAllOptions () {
    const separator = '&'
    let listOptions = ''
    let countSeparators = 0

    // SEARCH ALL FORM OPTIONS
    for (const nameFilter in this.selectSearchOriginal) {
      const nameFilterTranslated = this.selectSearchOriginal[nameFilter].translated
      const filterType = this.selectSearchOriginal[nameFilter].type
      const filterNewValue = this.newSelectSearchData[nameFilter].value
      const hasSegment = Object.prototype.hasOwnProperty.call(this.selectSearchOriginal[nameFilter], 'has_segment')
      const hasUniqueValue = Object.prototype.hasOwnProperty.call(this.selectSearchOriginal[nameFilter], 'is_unique_search')

      // HIDDEN SEARCH
      if (Object.prototype.hasOwnProperty.call(this.selectSearchOriginal[nameFilter], 'hidden_search')) {
        continue
      }
      // EMPTY
      if (this.isEmpty(filterNewValue)) {
        continue
      }
      // IS STRING , NO OPTION
      if (filterType) {
        // LIST TEXT VALUE
        if (filterType === 'list-text-value') {
          listOptions = countSeparators === 0 ? nameFilterTranslated + '=' + filterNewValue : listOptions + separator + nameFilterTranslated + '=' + filterNewValue
          // is unique, finish here
          if (hasUniqueValue) {
            return { listOptions: listOptions, segmentOptions: this.makeStringSegments() }
          }
          countSeparators++
          // hasUnicValue = true
          continue
        }
      }
      // IF DOES NOT HAS UNIC VALUE SELECTED
      // IF IS ARRAY SEARCH MANY OPTIONS IF IS NOT SEARCH JUST ONE OPTION
      const stringToPush = Array.isArray(filterNewValue) ? this.splitValuesAndReturnTitleArray(filterNewValue, nameFilterTranslated, nameFilter, filterType) : this.returnTitleString(filterNewValue, nameFilterTranslated, nameFilter)

      // ADD THE VALUE TO THE SEGMENT OPTIONS
      /* if (nameFilter == "city_location") { */
      if (hasSegment) {
        if (stringToPush !== 'empty') {
          this.segment.push(stringToPush)
          continue
        }
      }

      // ADD THE VALUE TO THE LIST OPTION
      if (stringToPush !== 'empty') {
        const prefix = nameFilterTranslated + '='

        listOptions = countSeparators === 0 ? prefix + stringToPush : listOptions + separator + prefix + stringToPush
      }
      countSeparators++
      continue
    }
    return { listOptions: listOptions, segmentOptions: this.makeStringSegments() }
  }

  makeStringSegments () {
    let string = ''

    for (const prop in this.segment) {
      string = Number(prop) === 0 ? this.segment[prop] : string + '/' + this.segment[prop]
    }

    return string
  }

  isEmpty (value) {
    // IS  ARRAY
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return true
      }
      return false
    }
    if (Number(value) === null) {
      return true
    }

    if (Number(value) === 0) {
      return true
    }

    if (value === '') {
      return true
    }

    return false
  }

  // GET FIELD TRANSTALED
  getFilterTranslated (field) {
    if (this.selectSearchTranslated[field]) {
      return this.selectSearchTranslated[field]
    }
  }

  returnTitleString (idValue, selectNameTranslated, nameItemSelected) {
    // if is null
    if (idValue === '') {
      return 'empty'
    }

    // SELECT THE TITLE
    return this.getNameOptionById(nameItemSelected, idValue, 'url_title')
  }

  getNameOptionById (optionName, optionId, InpuutName) {
    // GET THE OPTION ITEM
    const optionItem = this.formOptions[optionName]
    for (const prop in optionItem) {
      // IF IS THE SAME ID THAT I AM SELECTING
      if (Number(optionItem[prop].id) === Number(optionId)) {
        // RETURN THE TITLE
        return optionItem[prop][InpuutName]
      }
    }
  }

  splitValuesAndReturnTitleArray (mySplitResult, selectNameTranslated, nameItemSelected, filterType) {
    let urlTitle = ''
    let separator = '-e-'
    let counter = 0
    let counterHasValue = 0

    if (filterType === 'list-options-between' || filterType === 'list-value-between') {
      separator = '-a-'
    }
    for (let i = 0; i < mySplitResult.length; i++) {
      // VALUE IF IS SELECTED
      const optionId = mySplitResult[i]
      if (optionId !== 0 && optionId !== 'null' && optionId !== null && optionId !== 'undefined') {
        // SE FOR DATAS 20.10.2019-A-10.10.2019
        if (filterType === 'list-value-between') {
          urlTitle = this.makeUrlTitle(counter, urlTitle, optionId, separator)
        } else {
          // SELECT THE TITLE
          const optionTitle = this.getNameOptionById(nameItemSelected, optionId, 'url_title')
          // ADD THE URL 1-a-2
          urlTitle = this.makeUrlTitle(counter, urlTitle, optionTitle, separator)
        }

        counter++
        counterHasValue++
      } else {
        if (separator === '-a-') {
          if (Number(counter) === 0) {
            urlTitle = 'ate-'
            counter++
            continue
          }

          urlTitle = 'a-partir-de-' + urlTitle
          counter++
        }
      }
    }

    // if is null
    if (urlTitle === '') {
      return 'empty'
    }

    if (Number(counterHasValue) === 0) {
      return 'empty'
    }
    return urlTitle
  }

  getSeparatorName (field) {
    if (field.includes('.')) {
      const splitString = field.split('.')
      return { fieldName: splitString[0], fieldOBJ: splitString[1] }
    }

    return { fieldName: field, fieldOBJ: 'title' }
  }

  makeUrlTitle (counter, urlTitle, optionTitle, separator) {
    if (Number(counter) === 0) {
      return optionTitle
    }

    if (urlTitle === 'ate-') {
      return urlTitle + optionTitle
    }

    return urlTitle + separator + optionTitle
  }
}
export default FacyUrl

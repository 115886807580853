import './LoadingBar.css'
import CircularProgress, {circularProgressClasses} from "@mui/material/CircularProgress";
const LoadingBar = (props) => {
    return (<div className="loading-bar">
                <div className="loading-circle-display">
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: (theme) =>
                                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={55}
                        thickness={5}
                        {...props}
                        value={100}
                    />
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                            animationDuration: '550ms',
                            position: 'absolute',
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        size={55}
                        thickness={5}
                        {...props}
                    />

                </div>
                <p>Carregando ...</p>
            </div>);
}
export default LoadingBar;
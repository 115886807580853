import "./ModalImport.css"
import Modal from "../../../../components/Modal";
import SubmitBtn from "../../../../components/SubmitBtn";
import React, {useEffect, useState, useMemo} from 'react'
import {countImportProviders, importProviders} from "../../../../store/modules/admin/providersSliceActions";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel'

const ModalImport = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        procesing: false,
        status: 'not_started',
        pages: {total: 0, current: 0},
        result: {updated_users: 0, created_users: 0},
        resultData: {updated_users: 0, created_users: 0},
        importType: 'add'
    })

    const [page, setPage] = useState(0)


    const ImportTypeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, importType: event.target.value  }
        })
    }


    const submitHandler = event => {
        event.preventDefault();
        setData((prevState) => {
            return {...prevState, procesing: true}})
        // filters
        const wait = setTimeout(() => {
            clearTimeout(wait)
            /*dispatch(countImportProviders(data.items)).then((numberPages) => {
                setData((prevState) => {
                    return {...prevState, pages: { total: numberPages, current: 1}, status: 'is_processing'}})
            })*/

            dispatch(importProviders({type: data.importType})).then(() => {
                Swal.fire({
                    title: 'Sucesso',
                    text: 'Os usuários foram inseridos com sucesso',
                    icon: 'success'
                })
                props.onClose()
            }).catch(error => {
                // SET ERROR 500
                if(error.status === 500) {
                    Swal.fire({
                        title: 'Erro !!',
                        text: error.data.message,
                        icon: 'error'
                    })
                }
            })
        }, 2000)
    }

    useEffect(() => {
        let updated_users = 0
        let created_users = 0
        for (let i=1; i < data.pages.total + 1; i++) {
            const wait = setTimeout(() => {
                clearTimeout(wait)
                setPage(i);
                dispatch(importProviders({page: i, type: data.importType})).then((result) => {
                    updated_users = result.updated_users + updated_users
                    created_users = result.created_users + created_users
                    if(result.current_page == data.pages.total) {
                        setData((prevState) => {
                            return {
                                ...prevState,
                                procesing: false,
                                status: 'loaded',
                                pages: {total: data.pages.total, current: data.pages.total},
                                result: {updated_users: updated_users, created_users: created_users}
                            }
                        })
                    }

                })
            }, 10000)
        }
    }, [data.pages.total]);



    return (<Modal onClose={props.onClose} >
        <h1>Importar Forncedores</h1>
        <form method="POST" onSubmit={submitHandler} >
            <div className="modal-body">
                {(function() {
                    // LOADED
                    if (data.status == 'loaded') {
                        return <div className="notification notification-one  is-light">
                            <h3>Usuários Importados com Sucesso </h3>
                            <p>Usuários Atualizados - <span className="sp1" >{data.result.updated_users}</span> <br />
                                Usuários Criados - <span className="sp1" >{data.result.created_users}</span> <br /></p>
                        </div>
                    }
                    // IS PROCESSING
                    if (data.status == 'is_processing') {
                        return <div className="notification notification-one  is-light">
                            <h3>Processando ... </h3>
                            <p><span className="sp1" >{page}</span> de  <span className="sp1" >{data.pages.total}</span> </p>
                        </div>
                    }

                    // NOT STARTED
                    return    <RadioGroup aria-labelledby="demo-radio-buttons-group-label"
                                          defaultValue="female"
                                          name="radio-buttons-group">
                                <FormControlLabel value="add"  checked={data.importType == 'add'} onChange={ImportTypeHandler} control={<Radio />} label="Importar Apenas Novos Fornecedores" />
                                <FormControlLabel value="update" checked={data.importType == 'update'} onChange={ImportTypeHandler} control={<Radio />} label="Altualizar os Fornecedores Existentes" />
                                <FormControlLabel value="add-and-update" checked={data.importType == 'add-and-update'} onChange={ImportTypeHandler} control={<Radio />} label="Importar Novos e Atualizar Fornecedores Existentes" />
                            </RadioGroup>
                                })()}


            </div>
            <div className="modal-footer buttons is-centered">
                <button type="button" className="button is-light btn_cl_left"  onClick={props.onClose} ><span>Fechar</span></button>
                <SubmitBtn
                    processloading={data.procesing}
                    stylebutton="button btn_cl_left  add-alert is-info"
                    textbutton="Importar"
                />
            </div>
        </form>
    </Modal>);
};

export default ModalImport;
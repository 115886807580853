import React, { useState, useEffect, useContext } from 'react'
import {getSSOLogin} from "../store/modules/adminLogin/adminLoginSlice"
import { useDispatch } from "react-redux"
import AuthContext from '../store/modules/authContext';
import {useLocation} from "react-router-dom"
import LoadingBar from "../components/LoadingBar"
const CallBack = () => {
    const location = useLocation()
    const paramters = new URLSearchParams(location.search)
    const dispatch = useDispatch()
    const authCtx = useContext(AuthContext);
    const [userInput, setUserInput] = useState({
        procesing: false
    })
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setUserInput({procesing: true})
        // filters
        const wait = setTimeout(() => {
            clearTimeout(wait)
            dispatch(getSSOLogin()) .then(result => {
                window.location.href = result.authorize_url
            }, 2000)
        })

    }, []);
    return (<section>
        {loading && <LoadingBar /> }
    </section>);
};
export default CallBack;

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Link, useNavigate, useParams} from "react-router-dom"
import SubmitBtn from "../../../components/SubmitBtn"
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {formOptions} from "../../../store/modules/admin/providersSlice"
import { loadFormOptions, load_form, save } from "../../../store/modules/admin/providersSliceActions"
import Errors from "../../../hooks/ErrorInput"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Swal from "sweetalert2";
import { IMaskInput } from 'react-imask';
const Save = (type = 'create') => {
    const dispatch = useDispatch()
    const ErrorsData = new Errors()
    const navigate = useNavigate()
    const { id } = useParams();
    const formOptionsData = useSelector(formOptions)
    const actionText = type.type === 'create' ? 'Criar' : 'Editar'
    const [componentIsLoaded, setComponentIsLoaded] = useState(true)
    const [data, setData] = useState({
        procesing: false,
        id: null,
        /*address: [{id: 'temp', street: '', number: '', neighborhood: '', city: '', state: ''}],*/
        address: [],
        destroyed_address: [],
        cod_totvs: '',
        /*contact: [{id: 'temp', prefix: '', phone: ''}],*/
        contact: [],
        destroyed_contacts: [],
        /*bank_account: [{id: 'temp',bank_name: '', agency: '', account_number: '', account_digits: ''}],*/
        bank_account: [],
        destroyed_bank_accounts: [],
        email: '',
        classification: 'customer',
        category: '',
        status: 'active',
        document_type: 'commercial',
        personal: { cpf: '', name: '', last_name: '', gender: '' },
        commercial: { cnpj: '', razao_social: '', inscricao_municipal: '', inscricao_estadual: '' }
    })
    /*const initialErrorState = {
        address: [{id: 'temp', street: '', number: '', neighborhood: '', city: '', state: ''}],
        destroyed_address: [],
        cod_totvs: '',
        contact: [{id: 'temp', prefix: '', phone: ''}],
        destroyed_contacts: [],
        bank_account: [{id: 'temp',bank_name: '', agency: '', account_number: '', account_digits: ''}],
        destroyed_bank_accounts: [],
        email: '',
        classification: '',
        category: '',
        status: '',
        document_type: 'commercial',
        personal: { cpf: '', name: '', last_name: '', gender: '' },
        commercial: { cnpj: '', razao_social: '', inscricao_municipal: '', inscricao_estadual: '' },
        'commercial.cnpj': ''
    };*/

    const [errorList, setErrorList] = useState({})

    const ClassificationHandler  = (event) => {
        setData ((prevState) =>
        { return { ...prevState, classification: event.target.value}
        })
    }

    const EmailHandler  = (event) => {
        setData ((prevState) =>
        { return { ...prevState, email: event.target.value}
        })
    }

    const StatusChangeHandler  = (event) => {
        setData ((prevState) =>
        { return { ...prevState, status: event.target.value}
        })
    }

    const CategoryChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, category: event.target.value}
        })
    }

    const CNPJChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, commercial: { cnpj: event.target.value, razao_social: data.commercial.razao_social , inscricao_municipal: data.commercial.inscricao_municipal, inscricao_estadual: data.commercial.inscricao_estadual }}
        })
    }

    const RazaoSocialChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, commercial: { cnpj: data.commercial.cnpj , razao_social: event.target.value, inscricao_municipal: data.commercial.inscricao_municipal, inscricao_estadual: data.commercial.inscricao_estadual  }}
        })
    }

    const InscricaoEstadualChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, commercial: { cnpj: data.commercial.cnpj , razao_social: data.commercial.razao_social, inscricao_municipal: data.commercial.inscricao_municipal, inscricao_estadual: event.target.value  }}
        })
    }


    const InscricaoMunicipalChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, commercial: { cnpj: data.commercial.cnpj , razao_social: data.commercial.razao_social, inscricao_municipal: event.target.value, inscricao_estadual: data.commercial.inscricao_estadual  }}
        })
    }


    const CPFChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, personal: { cpf: event.target.value, name: data.personal.name, last_name: data.personal.last_name, gender: data.personal.gender, }}
        })
    }

    const NameChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, personal: { cpf: data.personal.cpf, name: event.target.value, last_name: data.personal.last_name, gender: data.personal.gender, }}
        })
    }

    const LastNameChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, personal: { cpf: data.personal.cpf, name: data.personal.name, last_name: event.target.value, gender: data.personal.gender, }}
        })
    }

    const GenderChangeHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, personal: { cpf: data.personal.cpf, name: data.personal.name, last_name: data.personal.last_name, gender: event.target.value, }}
        })
    }

    const codTotvsHandler = (event) => {
        setData ((prevState) =>
        { return { ...prevState, cod_totvs: event.target.value  }
        })
    }

    const streetHandler = index => (event, value) =>  {
        const address = data.address
        address[index].street = event.target.value
        setData ((prevState) =>
        { return { ...prevState, address: address  }
        })
    }

    const numberHandler = index => (event, value) =>  {
        const address = data.address
        address[index].number = event.target.value
        setData ((prevState) =>
        { return { ...prevState, address: address  }
        })
    }

    const stateHandler = index => (event, value) =>  {
        const address = data.address
        address[index].state = event.target.value
        setData ((prevState) =>
        { return { ...prevState, address: address  }
        })
    }

    const cityHandler = index => (event, value) =>  {
        const address = data.address
        address[index].city = event.target.value
        setData ((prevState) =>
        { return { ...prevState, address: address  }
        })
    }

    const phonePrefixHandler = index => (event, value) =>  {
        const contact = data.contact
        contact[index].prefix = event.target.value
        setData ((prevState) =>
        { return { ...prevState, contact: contact  }
        })
    }

    const phoneNumberHandler = index => (event, value) =>  {
        const contact = data.contact
        contact[index].phone = event.target.value
        setData ((prevState) =>
        { return { ...prevState, contact: contact  }
        })
    }

    const bankNameHandler = index => (event, value) =>  {
        const bank_account = data.bank_account
        bank_account[index].bank_name = event.target.value
        setData ((prevState) =>
        { return { ...prevState, bank_account: bank_account  }
        })
    }

    const bankAgencyHandler = index => (event, value) =>  {
        const bank_account = data.bank_account
        bank_account[index].agency = event.target.value
        setData ((prevState) =>
        { return { ...prevState, bank_account: bank_account  }
        })
    }

    const bankAccountNumberHandler = index => (event, value) =>  {
        const bank_account = data.bank_account
        bank_account[index].account_number = event.target.value
        setData ((prevState) =>
        { return { ...prevState, bank_account: bank_account  }
        })
    }

    const bankAccountDigitsHandler = index => (event, value) =>  {
        const bank_account = data.bank_account
        bank_account[index].account_digits = event.target.value
        setData ((prevState) =>
        { return { ...prevState, bank_account: bank_account  }
        })
    }


    useEffect(() => {
        dispatch(loadFormOptions('form'))
        if(type.type === 'edit') {
            dispatch(load_form(id)).then((list) => {
                Object.keys(list).forEach(function(key) {
                    if(data.hasOwnProperty(key)) {
                        if(key === 'address' || key === 'bank_account' || key === 'contact') {
                            if(!list[key].length) {
                                return
                            }
                        }

                        setData ((prevState) =>
                        { return { ...prevState, [key]: list[key]  }
                        })
                    }

                });
            }).catch(error => {
                console.log('error --->', error)
            })
        }

    }, []);

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addAddress = event => {
        setComponentIsLoaded(false)
        const newAddresValue = data.address
        newAddresValue.push({ id: 'temp',street: '' , number: '' , neighborhood : '', city: '' , state: '' })
        setData ((prevState) =>
        { return { ...prevState, address: newAddresValue}
        })
        setComponentIsLoaded(true)
    };

    const addContact = event => {
        setComponentIsLoaded(false)
        const newContactValue = data.contact
        newContactValue.push({ id: 'temp', prefix: '' , phone: '' })
        setData ((prevState) =>
        { return { ...prevState, contact: newContactValue}
        })
        setComponentIsLoaded(true)
    };

    const removeAddress = event => {
        // ADD DESTROY DATA
        if(event.currentTarget.id !== 'temp') {
            const IdsToDestroy = data.destroyed_address
            IdsToDestroy.push(data.address[event.currentTarget.id].id)
            setData ((prevState) =>
            { return { ...prevState, destroyed_address: IdsToDestroy}
            })
        }

        const newAddresValue = data.address
        newAddresValue.splice(event.currentTarget.id, 1)
        setData ((prevState) =>
        { return { ...prevState, address: newAddresValue}
        })
    };

    const removeContact = event => {
        // ADD DESTROY DATA
        if(event.currentTarget.id !== 'temp') {
            const IdsToDestroy = data.destroyed_contacts
            IdsToDestroy.push(data.contact[event.currentTarget.id].id)
            setData ((prevState) =>
            { return { ...prevState, destroyed_contacts: IdsToDestroy}
            })
        }

        const newContactValue = data.contact
        newContactValue.splice(event.currentTarget.id, 1)
        setData ((prevState) =>
        { return { ...prevState, contact: newContactValue}
        })
    };

    const addBankAccount = event => {
        console.log('addingggg bank')
        setComponentIsLoaded(false)
        const newBankValue = data.bank_account
        newBankValue.push({id: 'temp',bank_name: '', agency: '', account_number: '', account_digits: ''})
        setData ((prevState) =>
        { return { ...prevState, bank_account: newBankValue}
        })
        setComponentIsLoaded(true)
    };

    const removeBankAccount = event => {
        // ADD DESTROY DATA
        if(event.currentTarget.id !== 'temp') {
            const IdsToDestroy = data.destroyed_bank_accounts
            IdsToDestroy.push(data.bank_account[event.currentTarget.id].id)
            setData ((prevState) =>
            { return { ...prevState, destroyed_bank_accounts: IdsToDestroy}
            })
        }

        const newBankValue = data.bank_account
        newBankValue.splice(event.currentTarget.id, 1)
        setData ((prevState) =>
        { return { ...prevState, bank_account: newBankValue}
        })
    };

    const DocumentTypeHandler  = (event) => {
        setData ((prevState) =>
        { return { ...prevState, document_type: event.target.value  }
        })
    }


    const submitHandler = event => {
        event.preventDefault();
        setData ((prevState) =>
        { return { ...prevState, procesing: true  }
        })

        // filters
        const wait = setTimeout(() => {
            clearTimeout(wait)
            // DISPATCH
            dispatch(save({action: type.type , data: data})).then((res) => {
                const actionText = type.type === 'create' ? 'criado' : 'Editado'
                // SUCCESS MESSAGE
                Swal.fire({
                    title: 'Suceso !!',
                    text:  `Fornecedor ${actionText} com Sucesso`,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate('/admin/fornecedores')
                })
            }).catch(error => {
                console.log('errorrrrrrr', error)
                // RESET ERROR LIST
                setErrorList({})
                // SET EERROR REQUEST
                ErrorsData.record(error.data.errors, 'form')
                setErrorList(error.data.errors)
                /*Object.keys(error.data.errors).forEach(function(key) {
                    console.log('my key --->', key)
                    if(ErrorsData.has('form.' + key)){
                        console.log('keyyyy --->', key)
console.log('getttingggg my errrorrr --->', ErrorsData.get('form.' + key))
                        setErrorList ((prevState) =>
                        { return { ...prevState, [key]: ErrorsData.get('form.' + key)}
                        })
                    }
                });*/
                console.log('my erorrrssss listtt', errorList)
            }).then(() => {
                // LOADING FALSE
                setData ((prevState) =>
                { return { ...prevState, procesing: false  }
                })
            })
        })

    }

    return (<div className="content">
        <div className="card">
            <div className="card-header form-list-header">
                <h2>{actionText}  Fornecedor</h2>
            </div>
            <div className="card-body  card_form ">
                <form className="form-area" onSubmit={submitHandler}  >
                    <div className="columns">
                        <div className="column">
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Dados Gerais" value="1" />
                                            <Tab label="Endereços" value="2" />
                                            <Tab label="Contato" value="3" />
                                            <Tab label="Dados Bancários" value="4" />
                                            {/*<Tab label="SGI" value="5" />*/}
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <fieldset>
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label>E-mail: </label>
                                                        <div className="control">
                                                            <input className="form-control ipone" type="text" placeholder="Email"  value={data.email}   onChange={EmailHandler}   />
                                                            {errorList.email && <p className="error-msg">{errorList.email}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="field">
                                                        <label>Cód. TOTVS</label>
                                                        <div className="control">
                                                            <input className="form-control ipone" type="text"  placeholder="Cód. TOTVS"  value={data.cod_totvs}   onChange={codTotvsHandler}  />
                                                            {errorList.cod_totvs && <p className="error-msg">{errorList.cod_totvs}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field radio-box-ls">
                                                        <label className="title">Classificação</label>
                                                        <div className="field">
                                                            <div className="control">
                                                                <RadioGroup row
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue="commercial"
                                                                            name="radio-buttons-group">
                                                                    {formOptionsData.value.classification.map((item, index) => {
                                                                        return (<FormControlLabel value={item.id} key={index}  checked={data.classification == item.id} onChange={ClassificationHandler} control={<Radio />} label={item.title} />)
                                                                    })}
                                                                </RadioGroup>
                                                                {errorList.hasOwnProperty('classification') && <p className="error-msg">{errorList['classification']}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="field radio-box-ls">
                                                        <label className="title">Status</label>
                                                        <div className="field">
                                                            <div className="control">
                                                                <RadioGroup row
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue="commercial"
                                                                            name="radio-buttons-group">
                                                                    {formOptionsData.value.status.map((item, index) => {
                                                                        return (<FormControlLabel value={item.id} key={index}  checked={data.status == item.id} onChange={StatusChangeHandler} control={<Radio />} label={item.title} />)
                                                                    })}
                                                                </RadioGroup>
                                                                {errorList.hasOwnProperty('status') && <p className="error-msg">{errorList['status']}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label>Tipo de Documento</label>
                                                        <div className="control">
                                                            <RadioGroup row
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="commercial"
                                                                        name="radio-buttons-group">
                                                                <FormControlLabel value="commercial"  checked={data.document_type == 'commercial'} onChange={DocumentTypeHandler} control={<Radio />} label="Comercial" />
                                                                <FormControlLabel value="personal" checked={data.document_type == 'personal'} onChange={DocumentTypeHandler} control={<Radio />} label="Pessoa Fisica" />
                                                            </RadioGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {data.document_type == 'commercial' &&
                                                <div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <div className="field">
                                                                <label>Razão Social</label>
                                                                <div className="control">
                                                                    <input className="form-control ipone"  placeholder="Razão Social"  type="text" value={data.commercial.razao_social}  type="text" onChange={RazaoSocialChangeHandler} />
                                                                    {errorList.hasOwnProperty('commercial.razao_social') && <p className="error-msg">{errorList['commercial.razao_social']}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <div className="field">
                                                                <label>CNPJ</label>
                                                                <div className="control">

                                                                    <IMaskInput
                                                                        className="form-control ipone"
                                                                        unmask={false}
                                                                        mask="00.000.000/0000-00"
                                                                        placeholder="CNPJ"
                                                                        value={data.commercial.cnpj}  type="text" onChange={CNPJChangeHandler}
                                                                    />
                                                                    {errorList.hasOwnProperty('commercial.cnpj') && <p className="error-msg">{errorList['commercial.cnpj']}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <div className="field">
                                                                <label>Inscrição Municipal</label>
                                                                <div className="control">
                                                                    <input className="form-control ipone"  placeholder="Inscrição Municipal"   value={data.commercial.inscricao_municipal}  type="text"  onChange={InscricaoMunicipalChangeHandler} />
                                                                    {errorList.hasOwnProperty('commercial.inscricao_municipal') && <p className="error-msg">{errorList['commercial.inscricao_municipal']}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <div className="field">
                                                                <label>Inscrição Estadual</label>
                                                                <div className="control">
                                                                    <input className="form-control ipone"  placeholder="Inscrição Estadual"   value={data.commercial.inscricao_estadual}  type="text" onChange={InscricaoEstadualChangeHandler} />
                                                                    {errorList.hasOwnProperty('commercial.inscricao_estadual') && <p className="error-msg">{errorList['commercial.inscricao_estadual']}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }

                                            {data.document_type == 'personal' &&
                                                <div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <div className="field">
                                                                <label>cpf: </label>
                                                                <div className="control">
                                                                    <IMaskInput
                                                                        className="form-control ipone"
                                                                        unmask={false}
                                                                        mask="000.000.000-00"
                                                                        placeholder="CPF"
                                                                        value={data.personal.cpf}
                                                                        onChange={CPFChangeHandler}
                                                                        type="text"
                                                                    />
                                                                    {errorList.hasOwnProperty('personal.cpf') && <p className="error-msg">{errorList['personal.cpf']}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <div className="field">
                                                                <label>Genero</label>
                                                                <div className="control">
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            value={data.personal.gender}
                                                                            onChange={GenderChangeHandler}
                                                                            label="gender"  >
                                                                            {formOptionsData.value.gender.map((item, index) => {
                                                                                return (<MenuItem value={item.id} key={index} >{item.title}</MenuItem>)
                                                                            })}
                                                                        </Select>
                                                                        {errorList.hasOwnProperty('personal.gender') && <p className="error-msg">{errorList['personal.gender']}</p>}
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <div className="field">
                                                                <label>nome: </label>
                                                                <div className="control">
                                                                    <input className="form-control ipone" type="text" placeholder="nome" value={data.personal.name}   onChange={NameChangeHandler}  />
                                                                    {errorList.hasOwnProperty('personal.name') && <p className="error-msg">{errorList['personal.name']}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <div className="field">
                                                                <label>sobrenome</label>
                                                                <div className="control">
                                                                    <input className="form-control ipone" type="text" placeholder="sobrenome" value={data.personal.last_name}   onChange={LastNameChangeHandler}  />
                                                                    {errorList.hasOwnProperty('personal.last_name') && <p className="error-msg">{errorList['personal.last_name']}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>

                                             }
                                        </fieldset>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        {data.address.map((item, index) => {
                                             return (
                                                    <div key={index} className="isRelative data-list-form">
                                                        <div className="remove-data">
                                                            <a href="#" id={index} onClick={removeAddress}>
                                                                <FontAwesomeIcon icon="minus-circle"/>
                                                            </a>
                                                        </div>
                                                       {/* {index > 0 && }*/}
                                                        <div className="columns">
                                                            <div className="column">
                                                                <div className="field">
                                                                    <label>Rua</label>
                                                                    <div className="control">
                                                                        <input className="form-control ipone"
                                                                               value={item.street}  onChange={streetHandler(index)}
                                                                               placeholder="Rua" type="text"/>
                                                                        {errorList.hasOwnProperty('address.'+ index +'.street') && <p className="error-msg">{errorList['address.'+ index + '.street']}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="column">
                                                                <div className="field">
                                                                    <label>Número</label>
                                                                    <div className="control">
                                                                        <input className="form-control ipone"
                                                                               value={item.number}  onChange={numberHandler(index)}
                                                                               placeholder="Number" type="text"/>
                                                                        {errorList.hasOwnProperty('address.'+ index +'.number') && <p className="error-msg">{errorList['address.'+ index + '.number']}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="columns">
                                                            <div className="column">
                                                                <div className="field">
                                                                    <label>Cidade</label>
                                                                    <div className="control">
                                                                        <input className="form-control ipone"
                                                                               value={item.city}  onChange={cityHandler(index)}
                                                                               type="text"
                                                                               placeholder="Cidade"/>
                                                                        {errorList.hasOwnProperty('address.'+ index +'.city') && <p className="error-msg">{errorList['address.'+ index + '.city']}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="column">
                                                                <div className="field">
                                                                    <label>Estado</label>
                                                                    <div className="control">
                                                                        <input className="form-control ipone"
                                                                               value={item.state}  onChange={stateHandler(index)}
                                                                               placeholder="Estadi" type="text"/>
                                                                        {errorList.hasOwnProperty('address.'+ index +'.state') && <p className="error-msg">{errorList['address.'+ index + '.state']}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        <div className="field has-text-centered add-field-area">
                                            <a href="#" className="add-field" onClick={() => addAddress()} >
                                                <FontAwesomeIcon icon="plus-circle" />
                                            </a>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <fieldset>
                                            {data.contact.map((item, index) => {
                                                return (
                                                    <div key={index} className="isRelative data-list-form">
                                                       {/* {index > 0 && }*/}
                                                        <div className="remove-data">
                                                            <a href="#" id={index} onClick={removeContact}>
                                                                <FontAwesomeIcon icon="minus-circle"/>
                                                            </a>
                                                        </div>

                                                        <div className="columns">
                                                            <div className="column">
                                                                    <div className="field">
                                                                        <label>Prefixo</label>
                                                                        <div className="control">
                                                                            <IMaskInput
                                                                                className="form-control ipone"
                                                                                unmask={false}
                                                                                mask="00"
                                                                                placeholder="Prefixo"
                                                                                value={item.prefix}  type="text" onChange={phonePrefixHandler(index)}
                                                                            />
                                                                            {/*<TextField
                                                                                placeholder="Prefixo"
                                                                                value={item.prefix}  onChange={phonePrefixHandler(index)}
                                                                                type="text"
                                                                                inputProps={{ maxLength: 2, className: 'form-control ipone'}}
                                                                                fullWidth
                                                                            />*/}
                                                                            {errorList.hasOwnProperty('contact.'+ index +'.prefix') && <p className="error-msg">{errorList['contact.'+ index + '.prefix']}</p>}
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div className="column">
                                                                    <div className="field">
                                                                        <label>Telefone</label>
                                                                        <div className="control">
                                                                            <IMaskInput
                                                                                className="form-control ipone"
                                                                                unmask={false}
                                                                                mask="00000-0000"
                                                                                placeholder="Telefone"
                                                                                value={item.phone}  type="text" onChange={phoneNumberHandler(index)}
                                                                            />
                                                                            {/*<TextField
                                                                                placeholder="Telefone"
                                                                                value={item.phone}  onChange={phoneNumberHandler(index)}
                                                                                type="text"
                                                                                inputProps={{ maxLength: 10, className: 'form-control ipone'}}
                                                                                fullWidth
                                                                            />*/}
                                                                            {errorList.hasOwnProperty('contact.'+ index +'.phone') && <p className="error-msg">{errorList['contact.'+ index + '.phone']}</p>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                );
                                            })
                                         }
                                        <div className="field has-text-centered add-field-area">
                                            <a href="#" className="add-field" onClick={() => addContact()} >
                                                <FontAwesomeIcon icon="plus-circle" />
                                            </a>
                                        </div>
                                        </fieldset>
                                    </TabPanel>
                                    <TabPanel value="4">
                                        <fieldset>
                                            {data.bank_account.map((item, index) => {
                                                return (
                                                    <div key={index} className="isRelative data-list-form">
                                                        {/*{index > 0 &&}*/}
                                                        <div className="remove-data">
                                                            <a href="#" id={index} onClick={removeBankAccount}>
                                                                <FontAwesomeIcon icon="minus-circle"/>
                                                            </a>
                                                        </div>

                                                        <div className="columns">
                                                            <div className="column">
                                                                <div className="field">
                                                                    <label>Banco</label>
                                                                    <div className="control">
                                                                        <input className="form-control ipone"  placeholder="Nome do Banco"  value={item.bank_name}  onChange={bankNameHandler(index)} type="text" />
                                                                        {errorList.hasOwnProperty('bank_account.'+ index +'.bank_name') && <p className="error-msg">{errorList['bank_account.'+ index + '.bank_name']}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="column">
                                                                <div className="field">
                                                                    <label>Agência</label>
                                                                    <div className="control">
                                                                        <input className="form-control ipone"  placeholder="Agência"   value={item.agency}  onChange={bankAgencyHandler(index)}   type="text" />
                                                                        {errorList.hasOwnProperty('bank_account.'+ index +'.agency') && <p className="error-msg">{errorList['bank_account.'+ index + '.agency']}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="columns">
                                                            <div className="column">
                                                                <div className="field">
                                                                    <label>Conta Corrente</label>
                                                                    <div className="control">
                                                                        <IMaskInput
                                                                            className="form-control ipone"
                                                                            unmask={false}
                                                                            mask="0000000000000"
                                                                            placeholder="Número da Conta"
                                                                            value={item.account_number}  type="text" onChange={bankAccountNumberHandler(index)}
                                                                        />

                                                                        {errorList.hasOwnProperty('bank_account.'+ index +'.account_number') && <p className="error-msg">{errorList['bank_account.'+ index + '.account_number']}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="column">
                                                                <div className="field">
                                                                    <label>Dígítos</label>
                                                                    <div className="control">
                                                                        <IMaskInput
                                                                            className="form-control ipone"
                                                                            unmask={false}
                                                                            mask="000"
                                                                            placeholder="Dígitos"
                                                                            value={item.account_digits}  type="text" onChange={bankAccountDigitsHandler(index)}
                                                                        />

                                                                        {/*<TextField
                                                                            placeholder="Dígitos"
                                                                            value={item.account_digits}  onChange={bankAccountDigitsHandler(index)}
                                                                            type="text"
                                                                            inputProps={{ maxLength: 3, className: 'form-control ipone'}}
                                                                            fullWidth
                                                                        />*/}
                                                                        {errorList.hasOwnProperty('bank_account.'+ index +'.account_digits') && <p className="error-msg">{errorList['bank_account.'+ index + '.account_digits']}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            );
                                        })
                                      }
                                            <div className="field has-text-centered add-field-area">
                                                <a href="#" className="add-field" onClick={() => addBankAccount()} >
                                                    <FontAwesomeIcon icon="plus-circle" />
                                                </a>
                                            </div>
                                        </fieldset>
                                    </TabPanel>
                                    {/*<TabPanel value="4"></TabPanel>*/}
                                </TabContext>
                            </Box>
                        </div>
                    </div>
                    {Object.keys(errorList).length > 0 &&
                        <div className="columns" >
                            <div className="column footer-form">
                                Confira os Campos - <br />
                                <p className="error-msg-4">
                                    {Object.keys(errorList).map((item, index) => {
                                        return (
                                            <span key={index}>{item} <br /></span>
                                        )
                                    })}</p>
                            </div>
                        </div>
                    }
                    <div className="columns">
                        <div className="column footer-form">
                            <div className="buttons is-centered">
                                <Link to="/admin/fornecedores" className="button is-light btn_cl_left" ><span>Cancelar</span></Link>
                                <SubmitBtn
                                    processloading={data.procesing}
                                    stylebutton="btn_cl_left is-link"
                                    textbutton="Salvar"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>)};

 export default Save;
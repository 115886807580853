export const mutations = {
    setFilter (state, action) {
        const data = action.payload
        state.filter.value[data.name].value = data.value
    },
    setFormOptions (state, action) {
        const data = action.payload
        console.log('dataa optionsssss --->', data)
        const name = Object.prototype.hasOwnProperty.call(data, 'formOptionName') ? data.formOptionName : 'formOptions'
        for (const prop in data.value) {
            console.log('prop ---->', prop)
            console.log('nameeeeeee ---->', name)
            console.log('itemmmmssssss ---->', state[name].items)
            // if has the value in list
            //if (state[name].items.includes(prop.toString())) {
                console.log('possuiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
            console.log('settinggg value', prop, data.value[prop])
                state[name].value[prop] = data.value[prop]
            //}
        }
    },
    setData (state, action) {
        const data = action.payload
        if (data.name.includes('.')) {
            const splitString = data.name.split('.')
            // LENGTH = 4
            if (splitString.length === 4) {
                state[splitString[0]][splitString[1]][splitString[2]][splitString[3]] = data.value
                return
            }
            // LENGTH = 3
            if (splitString.length === 3) {
                state[splitString[0]][splitString[1]][splitString[2]] = data.value
                return
            }
            // 2
            state[splitString[0]][splitString[1]] = data.value
            return
        }
        state[data.name] = data.value
    },
    setDisplay(state, action) {
        const data = action.payload
        const name = Object.prototype.hasOwnProperty.call(data, 'displayName') ? data.displayName : 'displayItems'
        state[name] = data.data
    },
    setDisplayPagination(state, action) {
        const data = action.payload.data
        const name = Object.prototype.hasOwnProperty.call(data, 'paginationName') ? data.paginationName : 'pagination'
        if (Object.prototype.hasOwnProperty.call(data, 'paginationName')) delete data.paginationName
        // Object.entries(state[name]))
        for (const [field, value] of Object.entries(data)) {
            state[name][field] = value
        }
    },
    setPaginationNumber(state, action ) {
        const paginationName = action.payload ? action.payload : 'pagination'
        if (!state[paginationName].to) {
            state[paginationName].pagination_numbers = []
        }

        let from = state[paginationName].current_page - state[paginationName].offset
        if (from < 1) {
            from = 1
        }
        let to = from + (state[paginationName].offset * 2)
        if (to >= state[paginationName].last_page) {
            to = state[paginationName].last_page
        }
        let pagesArray = []
        while (from <= to) {
            pagesArray.push(from)
            from++
        }

        state[paginationName].pagination_numbers = pagesArray
        console.log('pagination valuessss', Object.entries(state[paginationName]))
    },
    set_data_deleted (state, action) {
        const data = action.payload
        let count = 0

        for (const prop in data.index) {
            state.displayItems.splice(data.index[prop], 1)
            count++
        }
        // set pagination
        state.pagination.total = state.pagination.total - count
        state.pagination.current_page = 1
    },
    reset_select_items (state) {
        state.selectedItems = []
    }
}

import { useDispatch, useSelector } from "react-redux"
import {
    pagination,
    displayItems,
    modal,
    filter,
    formOptions,
    initFilterValue
} from "../../../store/modules/admin/accessSlice"
import { setLoading } from "../../../store/modules/admin/mainSliceAction"
import {
    changePagePagination,
    loadDisplay,
    setFiltersList, setInitialValues,
    setModal,
    setSelectedItems,
    setFilter,
    loadFormOptions,
    clearFilters
} from "../../../store/modules/admin/accessSliceAction"
import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalDelete from "./inc/ModalDelete";
import ModalCreate from "./inc/ModalCreate";
import Pagination from "../../../components/Pagination";
import OneSeach from "../../../components/OneSeach";
import SetUrlFilerString from "../../../hooks/SetUrlFilerString";
import MakeFilterSearchUrl from "../../../hooks/MakeFilterSearchUrl";
import FacyUrl from "../../../hooks/FacyUrl";
import {optionSelected} from "../../../hooks/Helpers";
import {Link, useLocation} from "react-router-dom";

const Display = () => {
    const [componentIsLoaded, setComponentIsLoaded] = useState(false);
    const dispatch = useDispatch()
    const displayItemsData = useSelector(displayItems)
    const paginationsData = useSelector(pagination)
    const filtersData = useSelector(filter)
    const formOptionsData = useSelector(formOptions)
    const initFilterValueData = useSelector(initFilterValue)

    const modalData = useSelector(modal)
    const location = useLocation()
    const paramters = new URLSearchParams(location.search)

    const openDeleteOneModal = event => {
        const index = event.currentTarget.id
        dispatch(setSelectedItems(index))
        dispatch(setModal({ 'name': 'deleteOne', 'value': true }) )
    };

    const openCreateModal = event => {
        dispatch(setModal({ 'name': 'create', 'value': true }) )
    };

    const closeDeleteModal = () => {
        dispatch(setModal({ 'name': 'deleteOne', 'value': false }))
    };

    const closeCreateModal = () => {
        dispatch(setModal({ 'name': 'create', 'value': false }))
    };

    const closeCreateModalAndReload = () => {
        dispatch(setModal({ 'name': 'create', 'value': false }))
        changePage(1)
    };

    const changePage = page => {
        // SET URL STRING TO SEARCH
        const pgFilterURLString = new SetUrlFilerString(filtersData.value, filtersData.original).getValue(page)
        const newURL = new FacyUrl(filtersData, formOptionsData).getURL(page, null)
        // LOADING
        dispatch(setLoading(true))

        Promise.all([
            dispatch(changePagePagination(page)),
            dispatch(loadDisplay(pgFilterURLString))
        ]).then((res) => {
            dispatch(setLoading(false))
            window.history.pushState('', '', newURL)
        })
    };

    const loadFilters = filter => {
        console.log('willl load filter', filter)
        dispatch(setFilter(filter))
    };
    // CHANGE FILTER
    useEffect(() => {
        console.log('foiiiiiiiiiiiiiiii')
        if(componentIsLoaded === true) {
            console.log('startinggg')
            //const page = paginationsData.current_page
            const page = 1
            const pgFilterURLString = new SetUrlFilerString(filtersData.value, filtersData.original).getValue(page)
            const newURL = new FacyUrl(filtersData, formOptionsData).getURL(page, null)
            Promise.all([
                dispatch(changePagePagination(page)),
                dispatch(loadDisplay(pgFilterURLString)),
            ]).then((res) => {
                dispatch(setLoading(false))
                window.history.pushState('', '', newURL)
            })
        }
    }, [filtersData.value.name.value])

    // IS LOADING FOR THE FIRST TIME
    useEffect(() => {
        const firtFilterValue = initFilterValueData
        // GET PAGA
        const page = paramters.get('pagina') ? paramters.get('pagina') : 1
        // SET FILTER VALUE
        const filterSearchURL = new MakeFilterSearchUrl(firtFilterValue, formOptionsData.value, location.search).getAllFilters()
        // SET URL STRING TO SEARCH
        const pgFilterURLString = new SetUrlFilerString(filterSearchURL, firtFilterValue).getValue(page)
        // SET ORIGINAL VALUES
        Promise.all([
            setComponentIsLoaded(false),
            dispatch(clearFilters()),
            dispatch(setLoading(true)),
            dispatch(setInitialValues({
                path: location.search,
                filters: firtFilterValue,
                page: page,
                options: formOptionsData.value
            })),
            dispatch(loadFormOptions('display')),
            dispatch(setFiltersList(filterSearchURL)),
            dispatch(loadDisplay(pgFilterURLString))
        ]).then((res) => {
            dispatch(setLoading(false))
            setComponentIsLoaded(true)
        })
    }, []);


    return (
        <div className="content">
            {(function() {
                if (modalData.deleteOne) {
                    return <ModalDelete onClose={closeDeleteModal} type="delete-one" />
                }

                if (modalData.create) {
                    return <ModalCreate onClose={closeCreateModal} onCloseAndReload={closeCreateModalAndReload}  />
                }


            })()}

            <div className="columns">
                <div className="title-area-display">
                    <h1 className="main-title">Usuarios com  Direito de Acesso</h1>
                    <p>Usuários com Acesso ao Sistema de Parâmetros</p>
                </div>

                <div className="column">
                    <ul className="nav-btn-actions-top">
                        <li><OneSeach namefilter="name"
                                      initialvalue={filtersData.value.name.value}
                                      placeholder="Busque pelo Nome"
                                      onLoadfilters={loadFilters}  /></li>
                        <li><a href="#"  className="button is-success" onClick={openCreateModal} ><FontAwesomeIcon icon="plus" /> Autorizar Acesso</a></li>
                    </ul>
                </div>
            </div>
            <div className="columns" id="main-content" >
                { componentIsLoaded &&
                    <div className="column">
                        {displayItemsData.length ?
                            <div className="card">
                                <div className="card-header card-display">
                                    <p> Registros Encontrados - {paginationsData.total} / Página atual - {paginationsData.current_page}</p>
                                </div>
                                <div className="card-body">
                                    <table className="table table-display">
                                        <thead>
                                        <tr>
                                            {/*<th>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={true} name="select-all" label="select-all"/>
                                            }
                                            label="Selecionar Todos" />
                                    </th>*/}
                                            <th>
                                                Imagem
                                            </th>
                                            <th>
                                                Informações
                                            </th>

                                            <th className="col_action">
                                                Ação
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {displayItemsData.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    {/*<td>
                                                <Checkbox checked={false} name={'select-' + item.id} />
                                            </td>*/}
                                                    <td>
                                                        <div className="columns list_desc">
                                                            <div className="column">
                                                                <p>
                                                                    Nome: {item.name} {item.last_name}<br/>
                                                                    Status: {optionSelected({
                                                                    idSelected: item.status,
                                                                    options: formOptionsData.value.status
                                                                })} <br/>
                                                                    E-mail: {item.email} <br/>
                                                                    CPF: {item.cpf}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="columns list_desc">
                                                            <div className="column">
                                                            <p>
                                                                {item.manager.length > 0 && <label>É Gerente: </label>}
                                                                <span className="list">
                                                                {item.manager.map((accessAreaItem, indexAreaIntem) => {
                                                                    return (<span key={indexAreaIntem}><Link
                                                                        to={`/admin/areas?titulo=${accessAreaItem.title}`}> {accessAreaItem.title}</Link><br/></span>)
                                                                })}
                                                                </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                        <div className="columns list_desc">
                                                            <div className="column">
                                                            <p>
                                                                {item.access_area.length > 0 &&
                                                                    <label>Sistemas de Acesso : </label>}
                                                                     <span className="list">
                                                                    {item.access_area.map((accessAreaItem, indexAreaIntem) => {
                                                                        return (<span key={indexAreaIntem}><Link
                                                                            to={`/admin/areas?titulo=${accessAreaItem.title}`}>{accessAreaItem.title}</Link><br/></span>)
                                                                    })}
                                                                    </span>
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td className="col_action">
                                                        <ul className="actions-table-list">
                                                            <li>
                                                                <a className="link delete_btn hint--top" href="#"
                                                                   aria-label="Deletar" id={index} onClick={openDeleteOneModal}>
                                                                    <FontAwesomeIcon icon="trash-alt"/>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div> : <div className="card card-empty-result"><div className="card-body" ><h4>Não Há Resutados</h4></div></div> }
                        <Pagination title="Usuário Encontrado"
                                    titleplural="Usuários Encontrados"
                                    paginationdata={paginationsData}
                                    changepage={changePage} />
                    </div>}
            </div>
        </div>);
};

export default Display;
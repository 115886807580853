import { createSlice } from "@reduxjs/toolkit";
import {mutations} from "./mutations";
const accessSlice = createSlice({
    name: 'adminAccess',
    initialState: {
        displayItems: [],
        pagination: { total: 0, per_page: 10, from: 1, to: 0, current_page: 1, last_page: 1, offset: 3, pagination_numbers: [] },
        selectedItems: [],
        modal: { deleteOne: false, deleteMany: false, create: false },
        filter: {
            value: {
                email: { value: '', translated: 'email', type: 'list-text-value' },
                status: { value: '', translated: 'status' },
                area: { value: '', translated: 'area' },
                manager: { value: '', translated: 'gerente' },
                is_manager: { value: '', translated: 'egerente' },
                name: { value: '', translated: 'nome', type: 'list-text-value' },
                last_name: { value: '', translated: 'sobrenome', type: 'list-text-value' },
                cpf: { value: '', translated: 'cpf', type: 'list-text-value' },
            },
            original: {},
            fullPath: ''
        },
        formOptions: {
            value: {user_access: []},
            original: {},
            items: []
        },
        componentIsLoaded: false
    },
    reducers: {
        clear_filters (state) {
            state.filter.value.email.value = ''
            state.filter.value.status.value = ''
            state.filter.value.area.value = ''
            state.filter.value.manager.value = ''
            state.filter.value.is_manager.value = ''
            state.filter.value.name.value = ''
            state.filter.value.last_name.value = ''
            state.filter.value.cpf.value = ''
        },
        ...mutations
    }
})

export const pagination = (state) => state.adminAccess.pagination;
export const displayItems = (state) => state.adminAccess.displayItems;
export const formOptions = (state) => state.adminAccess.formOptions;
export const selectedItems = (state) => state.adminAccess.selectedItems;
export const modal = (state) => state.adminAccess.modal;
export const filter = (state) => state.adminAccess.filter;
export const initFilterValue = () => {
    return {email: { value: '', translated: 'email', type: 'list-text-value' },
        status: { value: '', translated: 'status' },
        area: { value: '', translated: 'area' },
        manager: { value: '', translated: 'gerente' },
        is_manager: { value: '', translated: 'egerente' },
        name: { value: '', translated: 'nome', type: 'list-text-value' },
        last_name: { value: '', translated: 'sobrenome', type: 'list-text-value' },
        cpf: { value: '', translated: 'cpf', type: 'list-text-value' }}
};
export const componentIsLoaded = (state) => state.adminAccess.componentIsLoaded;
export const accesActions = accessSlice.actions;
export default accessSlice.reducer
import axios from "axios";
import {accesActions} from './accessSlice';
const  pageInfo = {
    load_display: process.env.REACT_APP_API_URL + 'api/admin/access',
    load_form_options: process.env.REACT_APP_API_URL + 'api/admin/access/load-form-options',
    store: process.env.REACT_APP_API_URL + 'api/admin/access/store',
    destroy: process.env.REACT_APP_API_URL + 'api/admin/access/destroy'
}

export const setFilter = (filter) => {
    console.log('name filterrrr', filter.name)
    return async (dispatch) => {
        dispatch(accesActions.setData({ name: 'filter.value.' + filter.name + '.value', value: filter.value }))
        return true
    }
}

export const setComponentIsLoaded = (status) => {
    return async (dispatch) => {
        dispatch(accesActions.setData({ name: 'componentIsLoaded', value: status}))
        return true
    }
}


export const setFiltersList = (filter) => {
    return async (dispatch) => {
        dispatch(accesActions.setData({ name: 'filter.value', value: filter }))
        return true
    }
}

export const loadDisplay = (newUrlPage) => {
    console.log(pageInfo.load_display + '?' + newUrlPage.filters + '&has_access_area=true&' + newUrlPage.pagination)
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await axios.get(pageInfo.load_display + '?' + newUrlPage.filters + '&has_access_area=true&' + newUrlPage.pagination)
            return await response.data;
        };

        try {
            const response = await fetchData();
            // SET DISPLAY
            dispatch(accesActions.setDisplay({ data: response.data }))
            // SET PAGINATION
            dispatch(accesActions.setDisplayPagination({ data: response.pagination }))
            // SET PAGGINATION NUMBERS
            dispatch(accesActions.setPaginationNumber())
            // RETURN
            return await true

        } catch (error) {
            console.log(error)
        }
    }
}

export const setInitialValues = (data) => {
    return (async (dispatch) => {
        // SET PAGE
        dispatch(accesActions.setData({ name: 'pagination.current_page', value: Number(data.page) }))

        // SET FILTERS
        dispatch(accesActions.setData({name: 'filter.fullPath', value: data.path}))
        dispatch(accesActions.setData({name: 'filter.original', value: data.filters}))

        // SET FORM OPTIONS
        dispatch(accesActions.setData({name: 'formOptions.original', value: data.options}))
        dispatch(accesActions.setData({name: 'formOptions.items', value: data.options}))

    })
}

export const setSelectedItems = (index) => {
    return async (dispatch) => {
        dispatch(accesActions.setData({ name: 'selectedItems', value: [index] }))
    }
}
export const setModal = (data) => {
    return async (dispatch) => {
        dispatch(accesActions.setData({ name: 'modal.' + data.name, value: data.value }))
    }
}

export const changePagePagination = (page) => {
    return async (dispatch) => {
        return dispatch(accesActions.setData({ name: 'pagination.current_page', value: Number(page) }))
    }
}

export const save = (data) => {
    return async () => {
        const fetchData = async () => {
            const response = await axios.post(pageInfo.store, data.data)
            return await response.data;
        };

        try {
            const response = await fetchData();
            // RETURN
            return await response.data

        } catch (error) {
            throw error.response
        }
    }
}


export const loadFormOptions = (type) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await axios.get(pageInfo.load_form_options)
            return await response.data;
        };

        try {
            const response = await fetchData();
            dispatch(accesActions.setFormOptions({ value: response }))
            // RETURN
            return await true

        } catch (error) {
            throw error.response.data
        }
    }
}



export const destroy_all = (data) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await axios.post(pageInfo.destroy_all)
            return await response.data;
        };

        try {
            const response = await fetchData();
            return await response

        } catch (error) {
            throw error.response
        }
    }
}

export const destroy = (data) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await axios.post(pageInfo.destroy, {delete: data})
            return await response.data;
        };

        try {
            const response = await fetchData();
            // SPLICE ITEMS
            dispatch(accesActions.set_data_deleted(response))
            // SPLICE ITEMS
            dispatch(accesActions.reset_select_items())
            return await response

        } catch (error) {
            throw error.response
        }
    }
}



export const clearFilters = () => {
    return async (dispatch) => {
        return dispatch(accesActions.clear_filters())
    }
}

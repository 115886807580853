import axios from "axios";
import {providerActions} from './providersSlice';
const  pageInfo = {
    load_display: process.env.REACT_APP_API_URL + 'api/admin/providers',
    count_import_providers: process.env.REACT_APP_API_URL + 'api/admin/providers/count-totvs-import-provider',
    import_providers: process.env.REACT_APP_API_URL + 'api/admin/providers/totvs-import-provider',
    load_form_options: process.env.REACT_APP_API_URL + 'api/admin/providers/load-form-options',
    load_form: process.env.REACT_APP_API_URL + 'api/admin/providers/load-form',
    store: process.env.REACT_APP_API_URL + 'api/admin/providers/store',
    update: process.env.REACT_APP_API_URL + 'api/admin/providers/update',
    destroy: process.env.REACT_APP_API_URL + 'api/admin/providers/destroy',
    destroy_all: process.env.REACT_APP_API_URL + 'api/admin/providers/destroy-all',
}

export const setFilter = (filter) => {
    return async (dispatch) => {
        dispatch(providerActions.setData({ name: 'filter.value.' + filter.name + '.value', value: filter.value }))
        return true
    }
}

export const setComponentIsLoaded = (status) => {
    return async (dispatch) => {
        dispatch(providerActions.setData({ name: 'componentIsLoaded', value: status}))
        return true
    }
}


export const setFiltersList = (filter) => {
    return async (dispatch) => {
        dispatch(providerActions.setData({ name: 'filter.value', value: filter }))
        return true
    }
}

export const loadDisplay = (newUrlPage) => {
    console.log('willl loadddd --->', pageInfo.load_display + '?' + newUrlPage.filters + '&has_access_area=true&' + newUrlPage.pagination)
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await axios.get(pageInfo.load_display + '?' + newUrlPage.filters + '&has_access_area=true&' + newUrlPage.pagination)
            return await response.data;
        };

        try {
            const response = await fetchData();
            console.log('------>', response.data)
            // SET DISPLAY
            dispatch(providerActions.setDisplay({ data: response.data }))
            // SET PAGINATION
            dispatch(providerActions.setDisplayPagination({ data: response.pagination }))
            // SET PAGGINATION NUMBERS
            dispatch(providerActions.setPaginationNumber())
            // RETURN
            return await true

        } catch (error) {
            console.log(error)
        }
    }
}

export const setInitialValues = (data) => {
    return (async (dispatch) => {
        // SET PAGE
        dispatch(providerActions.setData({ name: 'pagination.current_page', value: Number(data.page) }))

        // SET FILTERS
        dispatch(providerActions.setData({name: 'filter.fullPath', value: data.path}))
        dispatch(providerActions.setData({name: 'filter.original', value: data.filters}))

        // SET FORM OPTIONS
        dispatch(providerActions.setData({name: 'formOptions.original', value: data.options}))
        dispatch(providerActions.setData({name: 'formOptions.items', value: data.options}))

    })
}

export const setSelectedItems = (index) => {
    return async (dispatch) => {
        dispatch(providerActions.setData({ name: 'selectedItems', value: [index] }))
    }
}
export const setModal = (data) => {
    return async (dispatch) => {
        dispatch(providerActions.setData({ name: 'modal.' + data.name, value: data.value }))
    }
}

export const changePagePagination = (page) => {
    return async (dispatch) => {
        return dispatch(providerActions.setData({ name: 'pagination.current_page', value: Number(page) }))
    }
}


export const save = (data) => {
    console.log('storingggg --->', data)
    const pageAction = data.action === 'create'  ? pageInfo.store  : pageInfo.update + '/' + data.data.id
        console.log('page url --->', pageAction)
    return async () => {
        const fetchData = async () => {
            const response = await axios.post(pageAction, data.data)
            return await response.data;
        };

        try {
            const response = await fetchData();
            // RETURN
            return await response.data

        } catch (error) {
            throw error.response
        }
    }
}


export const loadFormOptions = (type) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await axios.get(pageInfo.load_form_options)
            console.log('soloaded form options ', response.data)
            return await response.data;
        };

        try {
            const response = await fetchData();
            dispatch(providerActions.setFormOptions({ value: response }))
            // RETURN
            return await true

        } catch (error) {
            throw error.response.data
        }
    }
}

export const load_form = (paramter) => {
    return async () => {
        const fetchData = async () => {
            console.log('load_form --->', pageInfo.load_form + '/' + paramter)
            const response = await axios.get(pageInfo.load_form + '/' + paramter)
            console.log('soloaded form ', response.data)
            return await response.data;
        };

        try {
            const response = await fetchData();
            console.log('form is loaded', response)
            // RETURN
            return await response

        } catch (error) {
            throw error.response.data
        }
    }
}


export const countImportProviders = () => {
    return async () => {
        const fetchData = async () => {
            const response = await axios.get(pageInfo.count_import_providers)
            return await response.data;
        };

        try {
            const response = await fetchData();
            return await response

        } catch (error) {
            throw error.response
        }
    }
}

export const importProviders = (data) => {
    return async () => {
        const fetchData = async () => {
            const response = await axios.post(pageInfo.import_providers, data)
            return await response.data;
        };

        try {
            const response = await fetchData();
            return await response

        } catch (error) {
            throw error.response
        }
    }
}


export const destroy_all = (data) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await axios.post(pageInfo.destroy_all)
            return await response.data;
        };

        try {
            const response = await fetchData();
            return await response

        } catch (error) {
            throw error.response
        }
    }
}

export const destroy = (data) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await axios.post(pageInfo.destroy, {delete: data})
            return await response.data;
        };

        try {
            const response = await fetchData();
            // SPLICE ITEMS
            dispatch(providerActions.set_data_deleted(response))
            // SPLICE ITEMS
            dispatch(providerActions.reset_select_items())
            return await response

        } catch (error) {
            throw error.response
        }
    }
}



export const clearFilters = () => {
    return async (dispatch) => {
        return dispatch(providerActions.clear_filters())
    }
}

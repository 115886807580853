
class MakeFilterSearchUrl {
  constructor (nameList, formOptions, fullPath) {
    this.nameList = nameList
    this.formOptions = formOptions
    this.fullPath = fullPath
  }

  /* ----------------------------------------------
    TYPES -
    * list-options-between -  v-a-v / ate-v / a-partir-de-v
    * list-text-value      -  v
    * statandart           -  v-v-v

    GET PARAMTERS FROM -
    * segment
    * paramters
    -------------------------------------------------- */
  getParamterURL (nameFilter) {
    // FILTER VALUE
    const filterValue = this.nameList[nameFilter]
    // FILTER NAME TRANSLATED
    const filterType = filterValue.type
    // GET THE VALUE OF THE FILTER
    const valueOfFilter = this.getPropValue(filterValue)
    // IF  IS NOT NULL FILTER VALUE
    if (valueOfFilter !== null) {
      // TEXT VALUE - GET ONE VALUE
      if (filterType === 'list-text-value') {
        return [valueOfFilter]
      }
      // GET IDS FROM TITLES-
      const idTitles = this.getIdsUrlFromUrlTitle(nameFilter, valueOfFilter, filterType).toString()
      // PUT IT INTO ARRAY
      const listArray = this.splitAndAddInArray(idTitles, '-')
      if (listArray.length) {
        return listArray
      }
    }

    return null
  }


  getPropValue (filterValue) {
    const nameFilterTranslated = filterValue.translated.toString()
    const segment = Object.prototype.hasOwnProperty.call(filterValue, 'has_segment') ? filterValue.has_segment : null
    if (!segment) {
      return this.getParameterByName(nameFilterTranslated.toString())
    }
    return this.getSegment(segment)
  }

  splitAndAddInArray (strValue) {
    // SPLIT THE STRING
    const splitString = strValue.split('-')
    const list = []

    for (let i = 0; i < splitString.length; i++) {
      list.push(Number(splitString[i]))
    }

    return list
  }

  getIdsUrlFromUrlTitle (nameFilter, valueFilter, filterType = null) {
    /* SPLIT VALUE AND RETURN AN ARRAY -
           CONVERT THIS - 1-2-3
           TO THIS      - [1,2,3]
         */
    const splitValue = this.verifyItemSplit(nameFilter, valueFilter, filterType)

    let list = ''
    const separator = '-'

    // split those values
    for (const prop in splitValue) {
      const id = this.getIdProperty(nameFilter, filterType, splitValue[prop])
      list = Number(prop) === 0 ? id : list + separator + id
    }
    return list
  }

  getIdProperty (nameFilter, filterType, valueFiled) {
    let id = ''
    if (filterType === 'list-value-between') {
      id = valueFiled !== 0 ? valueFiled : 0
    } else {
      id = valueFiled !== 0 ? this.getIdOptionFromInputName(nameFilter, valueFiled) : 0
    }

    if (id === 'not_in_option') {
      return ''
    }
    return id
  }

  /*
        optionName = categoria
        urlTitle   = title
     */
  getIdOptionFromInputName (optionName, urlTitle) {
    if (!Object.prototype.hasOwnProperty.call(this.formOptions, optionName)) {
      return
    }

    for (const prop in this.formOptions[optionName]) {
      if (this.formOptions[optionName][prop].url_title === urlTitle) {
        return this.formOptions[optionName][prop].id
      }
    }

    return 'not_in_option'
  }

  // VERIFY TYPE OF SPLIT
  verifyItemSplit (nameFilter, valueFilter, filterType) {
    // SEPARATE BY 1-E-2
    return this.makeSplitItemsBetweenFeatures(nameFilter, valueFilter)
  }

  // SPLIT -E-
  makeSplitItemsBetweenFeatures (nameFilter, valueFilter) {
    const splitItem = '-e-'
    return valueFilter.split(splitItem)
  }

  getParameterByName (name) {
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(this.fullPath)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  getSegment (numberSegment) {
    const path = (this.fullPath).split('/')[numberSegment]
    if (path.indexOf('?') !== -1) {
      return path.split('?')[0]
    }

    if (path.indexOf('?') !== -1) {
      return path.split('&')[0]
    }
    return path
  }

  getAllFilters() {
    const newValueFilter = {}
    for (const prop in this.nameList) {
      newValueFilter[prop] = { value: this.nameList[prop].value, translated: this.nameList[prop].translated, type: this.nameList[prop].type }
      // get paramter filter like categoria=2-3 will return  category = [2,3]
      const valueFilter = this.getParamterURL(prop)
      if (valueFilter) {
        // IS ARRAY
        if (newValueFilter[prop].value instanceof Array) {
          // SET NEW VALUE FOT THE FILTER
          newValueFilter[prop].value = valueFilter
          continue
        }
        if (Object.prototype.hasOwnProperty.call(valueFilter, 0)) {
          newValueFilter[prop].value = valueFilter[0]
        }
      }
    }
    return newValueFilter
  }
}
export default MakeFilterSearchUrl

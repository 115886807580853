import { Route, Routes } from 'react-router-dom';
import LoginUser from './pages/Login';
import CallBack from "./pages/CallBack";
import Auth from "./pages/Auth";
import RegisterUser from './pages/Register';
import Error from './pages/Error';
import LoginLayout from "./layouts/LoginLayout";
import AdminLayout from "./layouts/AdminLayout";
import AdminHome from './pages/admin/Home'
import Fornecedores from './pages/admin/fornecedores/Display'
import SaveFornecedores from './pages/admin/fornecedores/Save'
import AdminAccess from './pages/admin/access/Display'

function App() {
  return (
      <main>
          <Routes>
            {/* public routes */}
            <Route element={<LoginLayout />}>
              <Route  path='/register' element={<RegisterUser />} >Register</Route>
              <Route  path="/" element={<LoginUser />}  layout={LoginLayout} >Login</Route>
              <Route  path="*" element={<Error />} >Error</Route>
            </Route>
            <Route  path="/callback" element={<CallBack />} >Login</Route>
            <Route  path="/auth" element={<Auth />} >Auth</Route>

            {/* admin routes */}
            <Route element={<AdminLayout />}>
              <Route  path='/admin/home' element={<AdminHome />} >Home</Route>
              <Route  path='/admin/fornecedores' element={<Fornecedores />} >Fornecedores</Route>
              <Route  path='/admin/fornecedores/criar' element={<SaveFornecedores type={"create"} />} >Salvar Fornecedores</Route>
              <Route  path='/admin/fornecedores/editar/:id' element={<SaveFornecedores type={"edit"} />} >Editar Fornecedores</Route>
              <Route  path='/admin/direito-de-acesso' element={<AdminAccess />} >Editar Sistemas</Route>
            </Route>
          </Routes>
      </main>
  );
}

export default App;

